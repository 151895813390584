/**
 * api 공통
 */
import NetworkService from "@/api/net.service";
import dayjs from "dayjs";

const NetApiServer = new NetworkService("")

/**
 *  리포트 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiReport = {

  /**
   * KOQUSS-40 Excel 파일 다운로드
   * @returns {Promise<AxiosResponse<any>>}
   */
  getKoquss40Report: async (params) => {
    return await NetApiServer.postExcel("/api/report/koquss40Report"
      , params, "koquss40Report" + "_" + dayjs(new Date()).format('YYYYMMDDHHmmss'));
  },

  /**
   * KOQUSS-33 Excel 파일 다운로드
   * @returns {Promise<AxiosResponse<any>>}
   */
  getKoquss33Report: async (params) => {
    return await NetApiServer.postExcel("/api/report/koquss33Report"
      , params, "koquss33Report" + "_" + dayjs(new Date()).format('YYYYMMDDHHmmss'));
  },

  /**
   * 식이 및 NRS2002 + 환자증상 Excel 파일 다운로드
   * @returns {Promise<AxiosResponse<any>>}
   */
  getNRS2002Report: async (params) => {
    return await NetApiServer.postExcel("/api/report/nrs2002Report"
      , params, "nrs2002Report" + "_" + dayjs(new Date()).format('YYYYMMDDHHmmss'));
  },

  /**
   * EORTC QLQ-STO22 Excel 파일 다운로드
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSTO22Report: async (params) => {
    return await NetApiServer.postExcel("/api/report/sto22Report"
      , params, "sto22Report" + "_" + dayjs(new Date()).format('YYYYMMDDHHmmss'));
  },

  /**
   * C30 Excel 파일 다운로드
   * @returns {Promise<AxiosResponse<any>>}
   */
  getC30Report: async (params) => {
    return await NetApiServer.postExcel("/api/report/c30Report"
      , params, "c30Report" + "_" + dayjs(new Date()).format('YYYYMMDDHHmmss'));
  },
}
